@use './defaults.scss' as def; 

/* HERO VIDEO */

.hero {
  width: 100%; 

  box-shadow: 0 0 50px #000C; 
  border-bottom-left-radius: 50vw; 
  border-bottom-right-radius: 50vw; 
}

/* BIO */

.bio {
  @include def.fonts; 
  
  margin: 4rem 0; 

  display: flex; 
  flex-direction: column; 
  align-items: flex-start; 
  
  .headline {
    @include def.headline-font; 
    
    margin-left: 3.6rem; 
    
    text-align: left; 
    
    display: flex; 
    flex-wrap: wrap; 
  }
  
  .copy {
    @include def.fonts; 
    
    max-width: 1024px; 
    margin: 1.2rem 2.4rem 0; 
    
    text-align: left; 
    font-size: 1.1rem; 
    font-weight: 700; 
  }
}

/* PROJECTS */

.projects {

  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-around; 

  --border-width: 11px; 
  --border-color: #333; 

  .project {
    width: 450px; 
    margin: 25px; 

    > .aspect {
      position: relative; 
      width: 100%; 
      padding-bottom: 100%; 
      overflow: hidden; 
  
      outline: var(--border-width) solid var(--border-color); 
      border-radius: 100vw; 
      transition: 0.333s box-shadow; 

      display: flex; 
      flex-direction: column; 
      justify-content: flex-start; 
      align-items: center; 
  
      .lunette {
        position: absolute; 
        width: 100%; 
        height: calc(50% - var(--border-width) / 2); 
        
        background-color: #FFF; 
        
        outline: var(--border-width) solid var(--border-color); 
        border-top-left-radius: 100vw; 
        border-top-right-radius: 100vw; 
  
        display: flex; 
        flex-direction: column; 
        justify-content: flex-end; 
        align-items: center; 

        .description {
          @include def.fonts; 
          width: 250px; 
        }
        
        .title {
          @include def.fonts; 
          @include def.headline-font; 
          
          font-size: 1.6rem; 
          line-height: 1; 
          text-align: center; 
        }

        .links {
          margin: 0 0 0.6em; 
        }
      }
      
      .image {
        position: absolute; 
        bottom: 0; 
        left: 0;
        height: 100%; 
        width: 100%;  
    
        object-position: bottom;
        object-fit: contain; 
      }
    }
    
    > .aspect:hover {
      animation: 0.333s rainbow 2 alternate; 
      box-shadow: 0 0 25px #333; 
    }
  }
}

// PROJECT OVERRIDES 

.project.memorygalleries {
  .aspect {
    .image {
      height: 50%; 
  
      object-fit: contain; 
      object-position: bottom; 
    }
  }
}

.project.numbergames {
  .aspect {
    .image {
      height: 50%; 
      
      object-fit: contain; 
    }
  }
}

.project.encoding {
  .aspect {
    .image {
      height: 50%; 

      object-fit: cover; 
    }
  }
}

.project.mansplane {
  .aspect {
    .image {
      height: 50%; 
  
      object-fit: contain; 
    }
  }
}

.project.cubism {
  .aspect {
    .image {
      height: 50%; 
  
      object-fit: cover; 
      object-position: top; 
    }
  }
}

.project.cubism {
  .aspect {
    .image {
      height: 50%; 
  
      object-fit: cover; 
      object-position: top; 
    }
  }
}

.project.networks {
  .aspect {
    .lunette {
      outline: none; 
    }
  }
}

.project.pixels {
  .aspect {
    .image {
      height: 50%; 
  
      object-position: 0 50%; 
      object-fit: cover; 
    }
  }
}

.project.takeanumber {
  .aspect {
    .image {
      height: 50%; 
  
      object-fit: fill;
    }
  }
}

.project.feedback {
  .aspect {
    .image {
      object-position: -4px 11px; 
    }
  }
}

.project.memorizationtext {
  .aspect {
    .image {
      height: 50%; 
      width: 120%; 
      
      object-fit: cover; 
      object-position: -25px 20%; 
    }
  }
}

.project.writingpi {
}

.project.illustrations {
  .aspect {
    .image {
      height: 50%; 
      
      object-position: 12.5% 0; 
    }
  }
}

/* FOOTER */

.footer {
  @include def.fonts; 

  margin: 8rem 0; 

  .headline {
    @include def.headline-font; 
  }
}