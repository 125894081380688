// FONTS 

@mixin fonts {
    text-align: center; 
    color: #333; 
}

@mixin headline-font {
    font-size: 2rem; 
    color: #333; 
}

// PROJECT ANIMATION 

@keyframes rainbow {
    0% {
      // background-image: radial-gradient(closest-side, #ffff 0, #0000 100%); 
    }
  
    100% {
      // background-image: radial-gradient(closest-side, #ffff 0, #000f 100%); 
    }
  }