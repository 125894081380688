// FONTS 

@font-face {
  font-family: 'FF Din';
  src: local('vla'), url('../fonts/FFDIN-Black.otf') format('opentype');
}

/* GLOBAL */

:root {
  box-sizing: border-box; 
}

::before, 
*, 
::after {
  box-sizing: inherit; 
  margin: 0; 
  padding: 0; 
  
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  object-fit: cover;
  object-position: center;
}

body {
  font-family: 'FF Din', 'Helvetica', sans-serif; 
}

a {
  text-decoration: none; 
  color: #00F; 
  font-weight: 900; 
}